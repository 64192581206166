import tw, { css } from "twin.macro";
import { Link, graphql } from "gatsby";
import {
	GatsbyImage,
	getImage,
	IGatsbyImageData,
} from "gatsby-plugin-image";
import SwiperCore, {
	Navigation,
	Pagination,
	Scrollbar,
	A11y,
	Autoplay,
	EffectFade,
} from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import Layout from "../components/Layout";
import SEO from "../components/SEO";
import ScheduleBanner from "../components/ScheduleBanner";
import {
	AppStyles,
	Body,
	ButtonBlue,
	ButtonWhite,
	H1,
	H2,
	H3,
	SerifSubtitle,
	Subtitle,
} from "../styles/AppStyles.styles.tw";

// Swiper Imports
SwiperCore.use([
	Navigation,
	Pagination,
	Scrollbar,
	A11y,
	Autoplay,
	EffectFade,
]);

type CardType = {
	title: string;
	image: {
		alt: string;
		asset: {
			gatsbyImageData: IGatsbyImageData;
		};
	};
	buttonText: string;
	buttonLink: string;
	body: string;
};

type GalleryType = {
	asset: {
		gatsbyImageData: IGatsbyImageData;
		url: string;
	};
	alt: string;
};

type SanityData = {
	sanityHome: {
		heroHeader: string;
		heroImage: {
			alt: string;
			asset: {
				gatsbyImageData: IGatsbyImageData;
				url: string;
			};
		};
		section1Array: [string];
		pageTitle: string;
		pageDescription: string;
		section1Body: string;
		section1GalleryImages: [GalleryType];
		section1Header: string;
		section2Body: string;
		section2Header: string;
		section2Image: {
			alt: string;
			asset: {
				gatsbyImageData: IGatsbyImageData;
			};
		};
		section3Body: string;
		section3Button: string;
		section3Header: string;
		section3Image: {
			asset: {
				gatsbyImageData: IGatsbyImageData;
				url: string;
			};
			alt: string;
		};
		section4Cards: [CardType];
	};
};

const BodyCopy = tw.p`whitespace-pre-line font-normal text-sm tracking-[2%] xl:(text-base leading-[24px])`;

function Home({ data }: any): JSX.Element {
	const swiperAutoplay = {
		delay: 2500,
		disableOnInteraction: false,
	};

	const homepageImage = css`
		background-image: url(${data.sanityHome.heroImage.asset
			.url});
		@media screen and (min-width: 768px) {
			min-height: 650px;
		}
	`;

	const downtownRiver = css`
		background-image: url(${data.sanityHome.section3Image
			.asset.url});
	`;

	return (
		<Layout title="home">
			<SEO
				title={data.sanityHome.pageTitle}
				description={data.sanityHome.pageDescription}
				keywords={data.sanityHome.keywords.join(", ")}
			/>
			<AppStyles>
				<section
					css={[
						tw`flex flex-col items-center justify-center py-32 text-center text-white bg-center bg-no-repeat bg-cover 2xl:py-48`,
						homepageImage,
					]}
				>
					<svg
						width="57"
						height="59"
						viewBox="0 0 57 59"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path
							d="M28.668 15.1912V29.7987"
							stroke="white"
							strokeWidth="1.5"
							strokeLinecap="round"
							strokeLinejoin="round"
						/>
						<path
							d="M28.668 29.6763L41.1246 22.4685"
							stroke="white"
							strokeWidth="1.5"
							strokeLinecap="round"
							strokeLinejoin="round"
						/>
						<path
							d="M28.6679 29.6763L16.146 22.4685"
							stroke="white"
							strokeWidth="1.5"
							strokeLinecap="round"
							strokeLinejoin="round"
						/>
						<path
							d="M36.3575 15.2892L28.668 29.6763L20.9541 15.2402V1L28.117 7.05279C28.2814 7.18812 28.4878 7.26212 28.7007 7.26212C28.9136 7.26212 29.1199 7.18812 29.2843 7.05279L36.5248 1.24489L36.3575 15.2892Z"
							stroke="white"
							strokeWidth="1.5"
							strokeLinecap="round"
							strokeLinejoin="round"
						/>
						<path
							d="M28.6685 29.6763L10.057 28.6273L2.29004 14.1953L20.9504 15.2524L28.2399 28.9416L28.6685 29.6763Z"
							stroke="white"
							strokeWidth="1.5"
							strokeLinecap="round"
							strokeLinejoin="round"
						/>
						<path
							d="M28.8924 29.664L47.259 28.6273L55.0219 14.1953L36.3615 15.2524L28.668 29.6763L28.8924 29.664Z"
							stroke="white"
							strokeWidth="1.5"
							strokeLinecap="round"
							strokeLinejoin="round"
						/>
						<path
							d="M19.0933 43.4961L28.603 58.2016L38.0108 43.4961"
							stroke="white"
							strokeWidth="1.5"
							strokeLinecap="round"
							strokeLinejoin="round"
						/>
						<path
							d="M10.0567 28.6274L1 42.4227L19.0931 43.4962L28.6682 29.6764L10.0567 28.6274Z"
							stroke="white"
							strokeWidth="1.5"
							strokeLinecap="round"
							strokeLinejoin="round"
						/>
						<path
							d="M47.259 28.6274L56.1076 42.4227L38.0104 43.4962L28.668 29.6764L47.259 28.6274Z"
							stroke="white"
							strokeWidth="1.5"
							strokeLinecap="round"
							strokeLinejoin="round"
						/>
					</svg>
					<div tw="max-w-sm lg:max-w-xl">
						<H1>{data.sanityHome.heroHeader}</H1>
					</div>
				</section>
				<section tw="grid grid-cols-2 lg:grid-cols-3 place-items-center">
					<Swiper
						tw="row-start-2 col-span-2 lg:row-start-1 lg:col-span-1 w-full h-80 lg:h-full"
						slidesPerView={1}
						autoplay={swiperAutoplay}
						effect="fade"
					>
						{data.sanityHome.section1GalleryImages.map(
							(image: GalleryType, i: number) => (
								<SwiperSlide key={i}>
									<GatsbyImage
										image={
											getImage(
												image.asset.gatsbyImageData
											) as IGatsbyImageData
										}
										alt={image.alt}
										tw="w-full h-full object-cover object-center"
									/>
								</SwiperSlide>
							)
						)}
					</Swiper>

					<div tw="col-span-2 lg:col-start-2 bg-beige-light px-4 py-8 text-left text-black/70 w-full h-full flex justify-center items-center xl:(py-20)">
						<div tw="w-full xl:(w-[75%]) 2xl:(w-[57%]) max-w-[36rem]">
							<H3 tw="mb-2 font-extrabold text-3xl xl:(text-[40px] leading-[49px])">
								{data.sanityHome.section1Header}
							</H3>
							<BodyCopy>
								{data.sanityHome.section1Body}
							</BodyCopy>
							<div tw="grid place-items-center grid-cols-2 gap-x-2 gap-y-3 lg:gap-8 border-t border-b border-rust mt-6 py-5 lg:py-8 text-rust">
								{data.sanityHome.section1Array.map(
									(text: string, i: number) => (
										<Subtitle
											tw="text-sm md:text-base lg:text-xl"
											key={i}
										>
											{text}
										</Subtitle>
									)
								)}
							</div>
							<div tw="w-full mt-6 mb-3">
								<Link
									to="/virtual-tour"
									tw="w-full flex justify-center text-center py-2.5 border border-rust text-beige-light bg-rust uppercase text-sm font-semibold transition duration-300 ease-in-out hover:(bg-beige-light text-rust)"
								>
									Take a Virtual Tour
								</Link>
							</div>
						</div>
					</div>
				</section>
				<section tw="bg-navy">
					<div tw="container mx-auto flex justify-around items-center flex-wrap text-beige-light lg:pt-8">
						<div tw="space-y-4 px-4 py-8 lg:px-0 lg:pb-8 lg:pt-0 mx-0">
							<SerifSubtitle tw="capitalize">
								{data.sanityHome.section2Header}
							</SerifSubtitle>
							<Body>
								{data.sanityHome.section2Body} <br /> <br />{" "}
								Starting June 1, 2021, Encore is owned and
								managed by{` `}
								<a
									href="https://www.mccafferyinc.com"
									rel="noreferrer"
									target="_blank"
									tw="font-bold underline"
								>
									McCaffery
								</a>
								.
							</Body>
						</div>
						<GatsbyImage
							image={
								getImage(
									data.sanityHome.section2Image.asset
										.gatsbyImageData
								) as IGatsbyImageData
							}
							alt={data.sanityHome.section2Image.alt}
						/>
					</div>
				</section>
				<section
					css={[
						tw`py-48 text-center text-white bg-bottom bg-no-repeat bg-cover lg:py-64`,
						downtownRiver,
					]}
				>
					<div tw="container mx-auto px-4 space-y-4 relative -top-32 lg:-top-48">
						<H2>{data.sanityHome.section3Header}</H2>
						<Body tw="max-w-xs lg:w-96 mx-auto">
							{data.sanityHome.section3Body}
						</Body>
						<div>
							<Link to="/neighborhood">
								<ButtonBlue>
									{data.sanityHome.section3Button}
								</ButtonBlue>
							</Link>
						</div>
					</div>
				</section>
				<section tw="bg-pine py-16 lg:py-24 text-white">
					<div tw="container mx-auto grid justify-items-center items-start gap-8 grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
						{data.sanityHome.section4Cards.map(
							(card: CardType, i: number) => (
								<div tw="text-center space-y-4" key={i}>
									<GatsbyImage
										image={
											getImage(
												card.image.asset.gatsbyImageData
											) as IGatsbyImageData
										}
										alt={card.image.alt}
										tw="mx-auto pb-2 w-80 h-64 object-cover"
									/>
									<SerifSubtitle>{card.title}</SerifSubtitle>
									<Body tw="w-72 lg:w-80 mx-auto">
										{card.body}
									</Body>
									<div>
										<Link to={card.buttonLink}>
											<ButtonWhite>
												{card.buttonText}
											</ButtonWhite>
										</Link>
									</div>
								</div>
							)
						)}
					</div>
				</section>
				<ScheduleBanner />
			</AppStyles>
		</Layout>
	);
}

export default Home;

export const query = graphql`
	{
		sanityHome {
			heroHeader
			heroImage {
				alt
				asset {
					gatsbyImageData
					url
				}
			}
			section1Array
			pageTitle
			pageDescription
			keywords
			section1Body
			section1GalleryImages {
				asset {
					gatsbyImageData
				}
				alt
			}
			section1Header
			section2Body
			section2Header
			section2Image {
				alt
				asset {
					gatsbyImageData
				}
			}
			section3Body
			section3Button
			section3Header
			section3Image {
				asset {
					gatsbyImageData
					url
				}
				alt
			}
			section4Cards {
				title
				image {
					alt
					asset {
						gatsbyImageData
					}
				}
				buttonText
				buttonLink
				body
			}
		}
	}
`;
